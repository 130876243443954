const IbanValidator = val => {
  if (val.length === 20) {
    let code = val.substring(4).concat(val.substring(0, 4));
    code = code.replaceAll(/E/g, "14");
    let n1 = parseInt(code.substring(0, 9)) % 97;
    let n2 = parseInt(n1.toString(10).concat(code.substring(9, 16))) % 97;
    let n3 = parseInt(n2.toString(10).concat(code.substring(16))) % 97;

    return n3 === 1;
  } else {
    return false;
  }
};

export default IbanValidator;
