<template>
  <add-worker @workerAdded="goToWorkers" @close="goToWorkers"></add-worker>
</template>

<script>
import AddWorker from "@/components/company/workers/AddWorker";
export default {
  components: {
    AddWorker
  },
  methods: {
    goToWorkers() {
      this.$router.push("/workers");
    }
  }
};
</script>
