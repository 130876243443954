<template>
  <div class="flex flex-col">
    <div class="flex flex-col w-full items-center">
      <div class="flex flex-col w-6/12 mobile:w-full mb-8">
        <form-label title="Lisa töötaja" :heading-level="2"></form-label>
        <form-label title="Üldinfo" :heading-level="3"></form-label>
        <div class="flex flex-col">
          <form-label
            title="Nimi"
            :required="true"
            :valid="!$v.editables.worker_name.$invalid"
            :error="$v.editables.worker_name.$error"
          ></form-label>
          <input
            class="mb-4"
            v-model="editables.worker_name"
            :class="[
              $v.$error && $v.editables.worker_name.$invalid
                ? 'shadow-dangeroutline'
                : ''
            ]"
          />
          <form-label
            title="Roll"
            :required="true"
            :valid="!$v.editables.permissions.$invalid"
            :error="$v.editables.permissions.$error"
          ></form-label>
          <div class="flex flex-row items-center mb-4">
            <span
              class="typcn typcn-user text-2xl text-green mr-2 hidden"
            ></span>
            <div class="w-full">
              <select
                v-model="editables.permissions"
                class="w-full bg-offwhite shadow focus:shadow-outline"
                :class="
                  $v.$error && $v.editables.permissions.$invalid
                    ? 'shadow-dangeroutline'
                    : ''
                "
              >
                <option
                  v-for="item in userRoles"
                  :value="item.role"
                  :key="item.key"
                >
                  {{ item.description }}
                </option>
              </select>
            </div>
          </div>
          <form-label title="Meiliaadress"></form-label>
          <input
            class="mb-4"
            v-model="emailInput"
            :class="[
              $v.$error && $v.editables.worker_email.$invalid
                ? 'shadow-dangeroutline'
                : ''
            ]"
          />

          <form-label title="Aadress" :required="false"></form-label>
          <div
            v-if="editables.location"
            class="mb-4 bg-offwhite w-full rounded-xl px-2 flex items-center"
          >
            <span>{{ editables.location.ipikkaadress }}</span>
            <span
              class="bg-danger p-2 rounded-full"
              @click="editables.location = null"
              ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
            /></span>
          </div>
          <div class="flex flex-row items-center mb-4">
            <location-inaddress
              v-if="!editables.location"
              :text="'Otsi töötaja aadressi'"
              @locationChanged="handleWorkerLocationChange"
              class="w-full"
            >
            </location-inaddress>
          </div>
          <form-label title="Telefoninumber"></form-label>
          <div class="flex flex-row items-center mb-4">
            <div class="w-full flex">
              <select
                v-model="countryCode"
                class="mobile:w-3/12 medium:w-3/12 w-2/12 mr-2"
              >
                <option value="+372"><img src="" />+372</option>
              </select>
              <input
                class="mobile:w-9/12 medium:w-9/12 w-10/12 add-project-input"
                v-model="phoneNumberInput"
                type="tel"
                pattern="[0-9]*"
              />
            </div>
          </div>
          <form-label title="Isikukood"></form-label>
          <div class="flex flex-row items-center">
            <div class="w-full">
              <input
                type="text"
                v-model="editables.id_code"
                :class="
                  $v.$error &&
                  editables.id_code.length > 0 &&
                  (!$v.editables.id_code.maxLength ||
                    !$v.editables.id_code.minLength ||
                    !$v.editables.id_code.numeric ||
                    !$v.editables.id_code.validateIdCodeControlNumber)
                    ? 'shadow-dangeroutline'
                    : ''
                "
                class="bg-offwhite flex-grow mr-3 mobile:mr-0 shadow focus:shadow-outline mobile:mb-3 w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-6/12 mobile:w-full mobile:ml-0 mb-8">
        <form-label title="Pangakonto andmed" :heading-level="3"></form-label>
        <div class="flex flex-row items-center mb-4">
          <div class="w-full flex flex-col">
            <form-label title="Kontoga seotud nimi"></form-label>
            <input
              type="text"
              v-model="editables.iban_account_name"
              :class="
                $v.$error &&
                editables.iban_code !== null &&
                editables.iban_code.length > 0 &&
                (editables.iban_account_name === null ||
                  !$v.editables.iban_account_name.required)
                  ? 'shadow-dangeroutline'
                  : ''
              "
              class="bg-offwhite flex-grow mr-3 mobile:mr-0 shadow
            focus:shadow-outline w-full"
            />
          </div>
        </div>

        <form-label title="Arvelduskonto number (IBAN)"></form-label>
        <div class="flex flex-row items-center mb-4">
          <div class="w-full">
            <input
              type="text"
              v-model="editables.iban_code"
              class="bg-offwhite flex-grow mr-3 mobile:mr-0 shadow focus:shadow-outline w-full"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex mb-3 w-6/12 mx-auto" v-if="$v.$error">
      <div class="error-box w-full items-center">
        <div class="w-1/12 flex items-center justify-center">
          <span class="text-4xl typcn typcn-times leading-none"></span>
        </div>
        <div class="w-11/12 flex flex-col">
          <span
            v-if="
              $v.editables.worker_name.$error &&
                !$v.editables.worker_name.required
            "
            >Töötajal peab olema nimi</span
          >
          <span v-if="!$v.editables.worker_email.email">
            Meiliaadress aadress on ebakorrektne
          </span>
          <span v-if="!$v.editables.permissions.required">
            Töötajal peab olema määratud roll ettevõttes
          </span>
          <span
            v-if="
              !$v.editables.id_code.maxLength || !$v.editables.id_code.minLength
            "
            >Isikukood peab koosnema 11-st numbrist</span
          >
          <span v-if="!$v.editables.id_code.numeric"
            >Isikukood tohib ainult numbreid sisaldada</span
          >
          <span v-if="!$v.editables.id_code.validateIdCodeControlNumber"
            >Isikukoodi kontrollnumber ei klapi</span
          >
          <span
            v-if="
              editables.iban_code !== null &&
                editables.iban_code.length > 0 &&
                !$v.editables.iban_code.ibanValidator
            "
            >Arvelduskonto number ei ole valiidne</span
          >
          <span
            v-if="
              editables.iban_code !== null &&
                editables.iban_code.length > 0 &&
                !$v.editables.iban_account_name.required
            "
          >
            Palun sisestage arvelduskontoga seotud nimi
          </span>
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full items-center justify-center">
      <button
        @click="saveWorker"
        class="mr-3"
        :class="$v.$invalid ? 'new-button-disabled' : 'new-button-green'"
      >
        <span class="icon typcn typcn-tick"></span>
        <span class="label">Salvesta</span>
      </button>
      <button class="new-button-danger" @click="closeModalActive = true">
        <span class="typcn typcn-times icon"></span>
        <span class="label">Tühista</span>
      </button>
    </div>
    <confirm-modal
      v-if="closeModalActive"
      text="Olete kindel, et soovite töötaja lisamise tühistada? Salvestamata andmed kustuvad."
      @confirm="close"
      @closeModal="closeModalActive = false"
    ></confirm-modal>
  </div>
</template>

<script>
import LocationInaddress from "@/components/reusable/LocationInaddress";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import {
  maxLength,
  minLength,
  numeric,
  required,
  email
} from "vuelidate/lib/validators";
import RequestHandler from "@/assets/mixins/RequestHandler";
import IbanValidator from "@/assets/mixins/validators/IbanValidator";
import PersonalCodeValidator from "@/assets/mixins/validators/PersonalCodeValidator";

const ibanValidator = IbanValidator;
const personalCodeValidator = PersonalCodeValidator;

export default {
  name: "AddWorker",
  mixins: [RequestHandler],
  components: { LocationInaddress, ConfirmModal },
  data() {
    return {
      editMode: false,
      editables: {
        worker_email: "",
        worker_name: "",
        location: "",
        id_code: "",
        iban_account_name: "",
        iban_code: "",
        permissions: "",
        telephone_number: ""
      },
      countryCode: "+372",
      phoneNumberInput: "",
      emailInput: "",
      closeModalActive: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    saveWorker() {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/workers/add/",
          "post",
          true,
          this.editables
        ).then(res => {
          if (res.status === 201) {
            this.$emit("workerAdded");
          }
        });
      }
    },
    handleWorkerLocationChange(e) {
      this.editables.location = e;
    }
  },
  watch: {
    phoneNumberInput() {
      const test = new RegExp(/[a-zA-Z\s]/);
      if (this.phoneNumberInput !== null) {
        this.editables.telephone_number =
          this.countryCode + this.phoneNumberInput.replace(test, "");
      }
    },
    emailInput() {
      this.editables.worker_email = this.emailLowercased;
    }
  },
  computed: {
    sanitizedIban() {
      if (this.editables.iban_code === null) {
        return "";
      }
      return this.editables.iban_code.replaceAll(" ", "");
    },
    emailLowercased() {
      return this.emailInput.toLowerCase();
    }
  },
  validations() {
    if (
      this.editables.iban_code !== null &&
      this.editables.iban_code.length > 0
    ) {
      return {
        editables: {
          id_code: {
            maxLength: maxLength(11),
            numeric,
            minLength: minLength(11),
            personalCodeValidator
          },
          iban_code: {
            required,
            ibanValidator
          },
          iban_account_name: {
            required
          },
          worker_email: {
            email
          },
          worker_name: {
            required
          },
          permissions: {
            required
          }
        }
      };
    } else {
      return {
        editables: {
          id_code: {
            maxLength: maxLength(11),
            minLength: minLength(11),
            numeric,
            personalCodeValidator
          },
          worker_email: {
            email
          },
          worker_name: {
            required
          },
          permissions: {
            required
          }
        }
      };
    }
  }
};
</script>
