const PersonalCodeValidator = val => {
  let i;
  if (val === null || val === "") {
    return true;
  }
  let multiplier1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1],
    multiplier2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3],
    mod,
    total = 0;

  for (i = 0; i < 10; i++) {
    total += val.charAt(i) * multiplier1[i];
  }
  mod = total % 11;

  total = 0;
  if (10 === mod) {
    for (i = 0; i < 10; i++) {
      total += val.charAt(i) * multiplier2[i];
    }
    mod = total % 11;
    if (10 === mod) {
      mod = 0;
    }
  }
  return mod === parseInt(val.charAt(10));
};

export default PersonalCodeValidator;
